@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-arrow
		+normalizeSize(15, 26)
	&-close
		+normalizeSize(32, 32)
	&-fullScreen
		+normalizeSize(33, 33)
	&-instagram
		+normalizeSize(41, 40)
	&-linkedin
		+normalizeSize(38, 37)
	&-meatBalls
		+normalizeSize(71, 12)
	&-play
		+normalizeSize(18, 18)
	&-soundOff
		+normalizeSize(37, 33)
	&-soundOn
		+normalizeSize(38, 33)
	&-twitter
		+normalizeSize(40, 35)
