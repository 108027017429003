@use 'common'

.wrapper
	display: flex
	flex-wrap: wrap
	margin: 5rem 0 4.5rem

	&.isHidden
		display: none

.item
	width: 100%
	margin-bottom: 6rem
	padding-right: 2rem
	&:nth-last-child(1)
		margin-bottom: 0
	@media (min-width: common.$break48)
		margin-bottom: 8.125rem
		width: calc(50% - 7.8125rem)
		margin-left: 7.8125rem
		&:nth-last-child(1),
		&:nth-last-child(2)
			margin-bottom: 0
.link
	font-family: var(--font-ArchivoBlack)
	display: flex
	font-weight: 400
	font-size: 22px
	line-height: 24px
	text-transform: uppercase
	text-decoration: underline
	color: inherit
	width: fit-content
	&:hover
		text-decoration: none
.text
	font-family: var(--font-Switzer)
	font-weight: 400
	font-size: 15px
	line-height: 20px
	margin-top: 11px
	max-width: 16.5625rem
	a
		color: inherit
		text-decoration: none
		&:hover
			text-decoration: underline
