@use 'common'
@use 'sass:math'
.wrapper
	padding: 2rem 0 0
	width: 100%
	@media (min-width: common.$break48)
		padding: 4rem 0 0

.is_position_fixed
	color: #ECECE4
	position: fixed
	z-index: 1
	right: 0
	left: 0
	width: 100%
	margin: 2rem auto 0
	padding: 0 1rem
	@media (min-width: common.$break48)
		margin: 4rem auto 0
		padding: 0 2rem

.menuBtn
	display: flex
	align-items: center
	border: none
	background-color: transparent
	font-size: .75rem
	line-height: 0
	padding: 0
	color: inherit
	margin-left: auto
	height: 32px

	&.is_not_visible
		display: none

.menu
	display: none

	&.is_menu_open
		display: flex
		flex-direction: column
		align-items: center
		@media (min-width: common.$break48)
			flex-direction: row
			justify-content: space-between

.column
	width: math.div(100%, 3)

.links
	display: flex
	justify-content: center

.link
	display: flex
	align-items: center
	padding: 0 1rem
	font-family: var(--font-ArchivoBlack)
	font-weight: 400
	font-size: 22px
	line-height: 24px
	text-transform: uppercase
	text-decoration: none
	color: inherit

.closeBtnWrapper
	display: flex
	justify-content: center
	margin-top: 1rem
	@media (min-width: common.$break48)
		margin-top: 0
		justify-content: flex-end

.closeBtn
	border: none
	background-color: transparent
	font-size: 2rem
	line-height: 0
	padding: 0
	color: inherit
	@media (min-width: common.$break48)
		margin-right: 1.2rem
