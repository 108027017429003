@use 'common'
@use 'sass:math'

.wrapper
	//

.showreelWrapper
	position: relative
	line-height: 0

.showreel
	width: 100%
	height: 90vh
	object-fit: cover

.mobileGradient
	height: 6rem
	opacity: 1
	position: absolute
	bottom: 0
	left: 0
	right: 0
	background: linear-gradient(to top, black, transparent)
	@media (min-width: common.$break48)
		display: none

.mobileSpacer
	height: 4.5rem
	background-color: #000
	@media (min-width: common.$break48)
		display: none

.content
	position: absolute
	bottom: 0
	left: 0
	right: 0
	display: flex
	flex-direction: column
	align-items: center
	margin-bottom: 3rem
	color: common.$primary-color
	@media (min-width: common.$break48)
		margin-bottom: 4rem

.title
	font-family: var(--font-ArchivoBlack)
	font-weight: 400
	font-size: 20px
	line-height: 22px
	text-transform: uppercase
	@media (min-width: common.$break48)
		font-size: 22px
		line-height: 24px

.name
	font-weight: 400
	font-size: 2.75rem
	font-family: var(--font-Mulish)
	line-height: 3.55rem
	margin: 0
	text-align: center
	padding: 0 1rem
	@media (min-width: common.$break48)
		font-size: 3.75rem
		line-height: 4.875rem

.description
	font-family: var(--font-Switzer)

	font-size: 15px
	line-height: 20px
	text-align: center

.sound
	display: none
	margin-left: auto
	background-color: transparent
	border: none
	font-size: 1.5rem
	margin-right: 1.875rem
	margin-right: 0.5rem
	margin-top: 1rem
	@media (min-width: common.$break48)
		font-size: 37px
		margin-right: 3.75rem

	&.is_icon_visible
		display: flex

.projects
	display: flex
	flex-wrap: wrap

.project
	position: relative
	width: 100%
	&:hover
		.projectContent
			@media (min-width: common.$break48)
				display: flex
				align-items: center
	@media (min-width: common.$break48)
		width: 50%

.image
	position: relative
	display: flex
	object-fit: cover
	aspect-ratio: 334/236

.projectContent
	display: flex
	flex-direction: column
	position: absolute
	bottom: 2.5rem
	left: 0
	right: 0
	pointer-events: none
	color: common.$primary-color
	padding: 0 1rem
	@media (min-width: common.$break48)
		display: none

.projectTitle
	font-family: var(--font-ArchivoBlack)
	text-transform: uppercase
	font-weight: 400
	font-size: 32px
	line-height: 35px

.projectDirector
	font-weight: 400
	font-size: 32px
	line-height: 41px

.allProjectsButton
	display: none
	@media (min-width: common.$break48)
		font-family: var(--font-ArchivoBlack)
		display: flex
		font-weight: 400
		font-size: 22px
		line-height: 24px
		text-transform: uppercase
		text-decoration: underline
		color: inherit
		width: fit-content
		margin: 1.625rem auto 3.125rem
		&:hover
			text-decoration: none
