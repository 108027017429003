@use 'common'
@use 'sass:math'
.videoWrapper
	position: relative

.video
	width: 100%
	height: auto
	@media (min-width: common.$break48)
		aspect-ratio: 16/9
		object-fit: cover
		height: 90vh

.image
	position: relative
	aspect-ratio: 16/9
	width: 100%
	height: 40vh

.video,
.image
	@media (min-width: common.$break48)
		aspect-ratio: 16/9
		width: 100%
		height: 90vh
		object-fit: cover

.fullScreen
	display: flex
	background-color: transparent
	border: none
	font-size: 1.5rem
	margin-right: 1.875rem
	position: absolute
	bottom: 0
	right: 0
	justify-content: flex-end
	margin-bottom: 2rem
	width: fit-content
	margin-left: auto
	@media (min-width: common.$break48)
		font-size: 33px
		margin-bottom: 4rem
		margin-right: 3.75rem

.wrapper
	margin-top: 2.125rem

.titleWrapper
	display: flex

.title
	font-family: var(--font-ArchivoBlack)
	text-transform: uppercase
	font-weight: 400
	font-size: 32px
	line-height: 35px
	margin: 0

.infoWrapper
	margin-top: 20px

.infoDescription,
.date
	font-family: var(--font-Switzer)
	font-weight: 400
	font-size: 15px
	line-height: 20px
.date
	margin-left: 0.5rem

.infoTitle
	font-weight: 400
	font-size: 24px
	line-height: 32px
	@media (min-width: common.$break48)
		font-size: 32px
		line-height: 41px
