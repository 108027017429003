@use 'common'
@use 'sass:math'

.container
	display: flex
	justify-content: space-between
	flex-direction: column
	height: 85vh

.wrapper
	display: flex
	flex-direction: column-reverse
	margin-top: 4rem
	@media (min-width: common.$break48)
		flex-direction: row

.nameWrapper
	@media (min-width: common.$break48)
		width: 60%

.name
	font-family: var(--font-ArchivoBlack)
	font-weight: 400
	font-size: 32px
	line-height: 35px
	text-transform: uppercase

.text
	font-weight: 400
	font-size: 24px
	line-height: 32px
	padding-right: 2em
	@media (min-width: common.$break48)
		font-size: 36px
		line-height: 46px

.image
	@media (min-width: common.$break48)
		width: 40%

.footer
	display: flex
	flex-direction: column
	margin-top: 4rem
	@media (min-width: common.$break48)
		flex-direction: row

.footerItem
	margin: 1rem 0
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break62)
		width: math.div(100%, 4)

.footerLink
	font-family: var(--font-ArchivoBlack)
	display: flex
	font-weight: 400
	font-size: 22px
	line-height: 24px
	text-transform: uppercase
	text-decoration: none
	color: inherit
	width: fit-content
	&:hover
		text-decoration: underline

.footerText
	font-family: var(--font-Switzer)
	font-weight: 400
	font-size: 15px
	line-height: 20px
	margin-top: 11px
	max-width: 16.5625rem
	padding-right: 2rem
	a
		color: inherit
		text-decoration: none
		&:hover
			text-decoration: underline
