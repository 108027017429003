@use 'common'
@use 'sass:math'

.wrapper
	//

.title
	font-family: var(--font-ArchivoBlack)
	text-transform: uppercase
	font-weight: 400
	font-size: 32px
	line-height: 35px
	margin-bottom: 3.625rem

.projectsDesktop
	display: none
	@media (min-width: common.$break62)
		display: flex
		flex-direction: column

.projectsMobile
	@media (min-width: common.$break62)
		display: none

.legendItemMobile
	font-family: var(--font-Switzer)
	font-size: .875rem
	@media (min-width: common.$break62)
		display: none

.video
	position: absolute
	height: 100%
	width: 100%
	display: flex

.legend,
.project
	display: none
	@media (min-width: common.$break62)
		display: grid
		grid-template-columns: 166fr 80fr 205fr 305fr 290fr 215fr

.legend
	font-family: var(--font-Switzer)
	font-weight: 400
	font-size: 15px
	line-height: 20px
	margin-bottom: 2.25rem

.spacer
	width: 8.125rem
	margin-right: 2.25rem

.project
	position: relative
	display: flex
	flex-direction: column
	justify-content: space-between
	border-bottom: 1px solid #B5B5B5
	text-decoration: none
	color: inherit
	aspect-ratio: 334/236
	overflow: hidden
	&:nth-last-child(1)
		border-bottom: none

	&:hover
		background-color: #DDDDD0
	@media (min-width: common.$break62)
		display: grid
		min-height: 7rem
		aspect-ratio: auto

.image
	display: none
	@media (min-width: common.$break62)
		position: relative
		display: block
		width: 8.125rem
		aspect-ratio: 130/80
		margin-right: 2.25rem
		line-height: 0
		margin-top: 1rem

.itemWrapperBox
	display: flex
	align-items: center

.itemWrapper
	padding: 4px 0
	@media (min-width: common.$break62)
		padding: 1rem 0
	@media not all and (min-width: common.$break62)
		width: 50%

.item
	display: flex
	justify-content: space-between
	font-size: 16px
	@media (min-width: common.$break62)
		padding-right: 1.5rem
		font-size: 24px
		line-height: 31px
		height: auto

.projectInfo
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: 1.25rem 1.75rem 1.25rem
	width: 100%
	height: 100%

.hasVideo
	z-index: 2
	color: common.$primary-color

.projectTitle
	display: flex
	align-items: center
	font-family: var(--font-ArchivoBlack)
	font-size: 1.25rem
	font-weight: 400
	text-transform: uppercase

.titleIcon
	display: flex
	padding-right: .75rem

.arrowIcon
	font-size: 23px
	margin-top: -0.75rem
	@media (min-width: common.$break62)
		display: none
