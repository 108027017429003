@use 'common'
@use 'sass:math'

.wrapper
	position: relative
	overflow: hidden

.wrapper,
.video
	display: flex
	width: 100%

.video
	object-fit: cover

.video,
.poster
	aspect-ratio: 334/236

.posterButton
	position: absolute
	inset: 0
	background-color: transparent
	border: none
	margin: 0

	&.is_hidden
		opacity: 0

.poster
	position: absolute
	inset: 0
	overflow: hidden
	display: flex
	background-color: #000000
