@use 'common'
@use 'sass:math'

.wrapper
	display: flex
	flex-wrap: wrap
	margin: 50px -0.5rem 7.5rem

.image
	display: flex
	justify-content: center
	align-items: center
	line-height: 0
	width: 100%
	padding: 0.4rem
	@media (min-width: common.$break48)
		width: math.div(100%, 3)

.imageIn
	border: none
	background-color: transparent
	padding: 0
